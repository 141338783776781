var app;
(function (app) {
    var localStore;
    (function (localStore) {
        'use strict';
        var MyStorageClass = /** @class */ (function () {
            /* @ngInject */
            MyStorageClass.$inject = ["$q"];
            function MyStorageClass($q) {
                this.$q = $q;
                this.myStorage = window.localStorage;
                this.LOCAL_STORAGE_EXP = 3; //3 (days)
                // this.expireCache();
                /* to update cache and see that it is working, we first have to:
                    1.update new cache date LOCAL_STORAGE_EXP,
                    2. then clear pre-existing with (  this.mylocalStore.clearAll() )
                    3.  then disable it, and finally reload the browser.
                */
            }
            /// currently we only have "ENG" AND "TH"
            MyStorageClass.prototype.setLanguage = function (lang, reset) {
                if (lang === void 0) { lang = 'ENG'; }
                if (reset === void 0) { reset = false; }
                var current_lang = this.myStorage.getItem("lang");
                current_lang = JSON.parse(current_lang);
                if (reset == true) {
                    current_lang = this.myStorage.setItem('lang', JSON.stringify(lang));
                }
                return current_lang || lang; // in case no caching        
            };
            MyStorageClass.prototype.expireCache = function (days) {
                if (days === void 0) { days = 0; }
                var expireIn = 1000 * 60 * 60 * 24 * days;
                var expireDate = (new Date()).getTime() + expireIn;
                //  console.log('human format date now : ',new Date(new Date().getTime()));
                //  console.log('future human format date: ',new Date(expireDate));
                return expireDate;
            };
            MyStorageClass.prototype.myGetItem = function (id) {
                var deffered = this.$q.defer();
                var item = this.myStorage.getItem(id);
                var exists = false;
                item = JSON.parse(item);
                /// check if we should remove cache based on expiry date
                if (item) {
                    console.log('item expiry, human format date now : ', new Date(new Date().getTime()));
                    console.log('item expiry future human format date: ', new Date(item._expires));
                    if ((new Date()).getTime() >= item._expires) {
                        this.removeMyItem(id);
                        exists = false;
                        console.log('cache expired!!!');
                        deffered.reject(this.output(false));
                        return { exists: exists, p: deffered.promise };
                    }
                }
                if (item) {
                    exists = true;
                    deffered.resolve(this.output(item).response);
                    // console.log(id, 'item found',item)
                }
                else {
                    exists = false;
                    console.log(id, 'item not found');
                    deffered.reject(this.output(false));
                }
                return { exists: exists, p: deffered.promise };
            };
            MyStorageClass.prototype.mySetItem = function (newid, data) {
                var deffered = this.$q.defer();
                // set cache expiry
                data._expires = this.expireCache(this.LOCAL_STORAGE_EXP);
                var item = this.myStorage.setItem(newid, JSON.stringify(data));
                if (this.myStorage.getItem(newid).length !== undefined) {
                    console.log(newid, 'yes it works');
                    return true;
                    //deffered.resolve(this.output(item))
                }
                else {
                    console.log(newid, 'item not set');
                }
                return false;
            };
            MyStorageClass.prototype.removeMyItem = function (removedid) {
                this.myStorage.removeItem(removedid);
                if (!this.myStorage.getItem(removedid)) {
                    console.log(removedid, 'item removed!');
                    return true;
                }
                else {
                    console.log(removedid, 'item not removed');
                    return false;
                }
            };
            MyStorageClass.prototype.clearAll = function () {
                console.log('all cache cleared!');
                this.myStorage.clear();
            };
            MyStorageClass.prototype.output = function (opts) {
                var resp = {
                    response: '',
                    success: false,
                    message: ''
                };
                if (opts) {
                    resp.response = opts;
                    resp.success = true;
                }
                else {
                    resp.message = 'no cache found';
                    resp.success = false;
                }
                return resp;
            };
            return MyStorageClass;
        }());
        localStore.MyStorageClass = MyStorageClass;
        angular
            .module('app.myLocalStorage', []);
        angular
            .module('app.myLocalStorage')
            .service('mylocalStore', MyStorageClass);
    })(localStore = app.localStore || (app.localStore = {}));
})(app || (app = {}));
